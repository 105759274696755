(function () {

	let isProduction = process.env.NODE_ENV === 'production';

	if (!isProduction)  {
		console.log('Dev-Mode: ' + !isProduction);
		document.write('<script async src=\'http://HOST:3030/browser-sync/browser-sync-client.js?v=2.24.7\'></script>'.replace('HOST', location.hostname));
	}

})();
