$(document).ready(function($) {

	let body = $('body');
	let popUpGalleryWrappers = $('.col-module__image-gallery-pop-up.has-gallery');

	if(popUpGalleryWrappers.length) {

		popUpGalleryWrappers.each(function() {

			let gallery = $(this).find('.pop-up-gallery');
			let closeFace = $(this).find('.pop-up-gallery__close-face');

			$(this).click(function() {
				$(gallery).addClass('pop-up-gallery--active');
				$(body).addClass('modal-open');
			});

			$(closeFace).click(function(event) {
				event.stopPropagation();
				$(gallery).removeClass('pop-up-gallery--active');
				$(body).removeClass('modal-open');
			});

		});

	}

});
