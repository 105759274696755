import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require('bootstrap');

require('./offcanvas');
require('./offcanvasMenu');
require('./popUpGallery');
// require('./navbarShrink');
require('./smoothScroll');
// require('./modal');

require('./aos');
require('./slideshow');

// require('./scrollspy');

require('./watchForHover');

require('./browserSync');

