$(document).ready(function($) {

	let toggleButton = $('.off-canvas-menu .menu-item-has-children');

	$(toggleButton).click(function() {

		if($(this).hasClass('active')) {
			$(this).removeClass('active');
		}
		else {
			$(this).addClass('active');
		}

	});

});
