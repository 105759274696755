import Swiper from 'swiper/dist/js/swiper.min.js';

$(document).ready(function() {

	let blockSlideshows = $('.block-slideshow');

	if(blockSlideshows.length) {

		blockSlideshows.each(function() {

			let id = $(this).attr('id');

			let spaceBetween = this.getAttribute('data-swiper-space-between');
			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let slidesPerViewLg = this.getAttribute('data-swiper-slides-per-view-lg');
			let slidesPerViewMd = this.getAttribute('data-swiper-slides-per-view-md');
			let slidesPerViewSm = this.getAttribute('data-swiper-slides-per-view-sm');
			let slidesPerViewXs = this.getAttribute('data-swiper-slides-per-view-xs');
			let slidesPerViewXxs = this.getAttribute('data-swiper-slides-per-view-xxs');

			let blockSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: false,
				speed: 500,
				spaceBetween: spaceBetween ? parseInt(spaceBetween, 10) : 0,
				slidesPerView: slidesPerViewLg ? parseInt(slidesPerViewLg, 10) : 2,
				slidesPerGroup: slidesPerViewLg ? parseInt(slidesPerViewLg, 10) : 2,
				watchOverflow: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,
				breakpoints: {
					1200: {
						slidesPerView: slidesPerViewMd ? parseInt(slidesPerViewMd, 10) : 2,
						slidesPerGroup: slidesPerViewMd ? parseInt(slidesPerViewMd, 10) : 2,
					},
					992: {
						slidesPerView: slidesPerViewSm ? parseInt(slidesPerViewSm, 10) : 1,
						slidesPerGroup: slidesPerViewSm ? parseInt(slidesPerViewSm, 10) : 1,
						slidesPerColumn: 2,
					},
					768: {
						slidesPerView: slidesPerViewXs ? parseInt(slidesPerViewXs, 10) : 1,
						slidesPerGroup: slidesPerViewXs ? parseInt(slidesPerViewXs, 10) : 1,
						slidesPerColumn: 2,
					},
					576: {
						slidesPerView: slidesPerViewXxs ? parseInt(slidesPerViewXxs, 10) : 1,
						slidesPerGroup: slidesPerViewXxs ? parseInt(slidesPerViewXxs, 10) : 1,
						slidesPerColumn: 2,
					}
				},

				pagination: {
					el: '.swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}

			});

		});

	}



	let imageVideoSlideshows = $('.image-video-slideshow.image-video-slideshow--active');

	if(imageVideoSlideshows.length) {

		imageVideoSlideshows.each(function() {

			let id = $(this).attr('id');

			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let imageVideoSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: 0,
				slidesPerView: 'auto',
				centeredSlides: false,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,
				effect: 'fade',

				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}

			});

			let slideshow = $('#' + id);
			let elem = $(slideshow).find('.image-video-slideshow__progress-bar');
			let interval = null;
			let autoplayTime = autoplay / 100;
			let width = 1;

			if(elem.length > 0) {

				imageVideoSwiper.on('slideChange', () => { move(id); });

				move();

			}

			function move() {

				clearInterval(interval);

				width = 1;
				interval = setInterval(frame, autoplayTime);

				function frame() {
					width++;
					$(elem).css('width', width + '%');
				}

			}

		});

	}



	let iconSlideshowsFixed = $('.icon-slideshow');

	if(iconSlideshowsFixed.length) {

		iconSlideshowsFixed.each(function() {

			let id = $(this).attr('id');

			let spaceBetween = this.getAttribute('data-swiper-space-between');
			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let iconSlideshowsSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: spaceBetween ? parseInt(spaceBetween, 10) : 50,
				slidesPerView: 'auto',
				centeredSlides: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,

				pagination: {
					el: '.swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}

			});

		});

	}



	let imageGallerySlideshows = $('.image-slideshow-gallery.image-slideshow-gallery--active');

	if(imageGallerySlideshows.length) {

		imageGallerySlideshows.each(function() {

			let id = $(this).attr('id');

			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let imageGallerySwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: 50,
				slidesPerView: 'auto',
				centeredSlides: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,
				breakpoints: {
					992: {
						spaceBetween: 15,
					},
				},

				pagination: {
					el: '.swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}

			});

			$('#' + $(this).closest('.accordion-body').attr('aria-labelledby')).click(function() {
				if($(this).hasClass('collapsed')) {
					setTimeout(() => {
						imageGallerySwiper.update();
					}, 1);
				}

			});

		});

	}

});
